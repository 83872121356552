import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Head from 'next/head';
import { Box } from '~/components';
import { useDefaultRouteForUser } from '~/lib/routing';

const HomePage: NextPage = () => {
  const router = useRouter();
  const redirect = useDefaultRouteForUser({ asObject: true });

  useEffect(() => {
    if (redirect) {
      router.push(redirect);
    }
  }, [redirect, router]);

  return (
    <Box height="calc(100vh - 48px)">
      <Head>
        <title>Graphika - Home</title>
        <meta name="description" content="SaaS app for Graphika" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </Box>
  );
};

export default HomePage;
